<template>
  <ion-card
    @click="toggleCardOpen"
    class="no-background item-container no-border-radius"
  >
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-align-items-center task-component">
        <ion-col v-if="img" size="2">
          <div class="img-container">
            <img :src="img" @error="handleImgError" />
          </div>
        </ion-col>
        <ion-col :size="img ? '10' : '12'">
          <ion-row class="ion-align-items-top ion-margin-bottom">
            <ion-col size="5" class="ion-no-padding item-text">
              <span>{{ title }}</span>
            </ion-col>
            <ion-col size="5" class="ion-no-padding item-text">
              {{ date }}
            </ion-col>
            <ion-col v-if="isDone" size="2" class="is-done is-done__yellow">
              DONE
            </ion-col>
            <ion-col v-else size="2" class="is-done is-done__yellow">
              TODO
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="item-text" style="font-weight: bolder">
              {{ description }}
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row v-if="cardOpen">
        <ion-col>
          <pdf src="sample-pdf.pdf" :page="1" />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</template>

<script>
import { IonRow, IonCol, IonGrid } from "@ionic/vue";
import pdf from "pdfvuer";
export default {
  name: "TaskComponent",
  components: {
    IonRow,
    IonCol,
    IonGrid,
    pdf,
  },
  props: {
    id: Number,
    img: String,
    title: String,
    description: String,
    date: String,
    isDone: Boolean,
    comment: String,
  },
  data() {
    return {
      cardOpen: false,
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    toggleCardOpen() {
      this.cardOpen = !this.cardOpen;
    },
  },
};
</script>

<style scoped>
/* * {
  border: 1px solid greenyellow;
} */

/* .emm {
  border: 0.5px solid red;
} */

.no-border-radius {
  border-radius: 0px;
}

.is-done {
  display: grid;
  place-items: center;
  max-height: 1.2rem;
  padding: 0.2rem;
  border-radius: 10px;
  color: var(--text-color-light);
  font-size: 0.6rem;
  font-weight: bold;
}

.is-done__green {
  background-color: green;
}

.is-done__yellow {
  background-color: rgb(194, 194, 21);
}

.item-text {
  padding: 0 0.1rem 0 0.5rem;
}

.img-container img {
  border-radius: 50%;
}

span {
  font-weight: bolder;
}

.padding-top {
  padding-top: 1rem;
}

.task-component {
  background-color: var(--text-color-light);
  padding: 1rem;
  border-radius: 10px;
}
</style>
